import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// RTK Slice
import { getAuthorisationDetails } from '../../redux/modules/authorisationDetailsSlice';
import { getReferenceData, setAppVersionState } from '../../redux/modules/referenceDataSlice';
import { resetStoreToInitialState } from '../../redux/actions/resetStore';
import { RESET_STORE_TO_INITIAL_STATE } from '../../redux/ActionTypeConstants';
// API Wrappers
import generateToken, { GenerateTokenData } from '../../redux/api/generateToken';
import registerDevice from '../../redux/api/registerDevice';

const useRegisterDeviceAndGenerateToken = () => {
  const dispatch: any = useDispatch();
  let appVersionCheckRef = useRef(false);
  let dataFetchedRef = useRef(false);

  const { registerDeviceLoading, generateTokenLoading, clientId, clientSecret } = useSelector(getAuthorisationDetails);
  const { appVersion } = useSelector(getReferenceData);

  useEffect(() => {
    if (appVersionCheckRef.current) return;
    appVersionCheckRef.current = true;

    if (process.env.REACT_APP_VERSION !== appVersion) {
      console.log('App version is outdated');
      dispatch(resetStoreToInitialState(RESET_STORE_TO_INITIAL_STATE));
      dispatch(setAppVersionState());
    }
  }, []);

  useEffect(() => {
    if (appVersionCheckRef.current) {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;

      if (registerDeviceLoading === 'idle' && (clientId === '' || clientSecret === '')) {
        dispatch(registerDevice(1));
      }
    }
  }, [registerDeviceLoading, clientId, clientSecret, appVersionCheckRef.current]);

  useEffect(() => {
    if (registerDeviceLoading === 'succeeded' && generateTokenLoading !== 'loading') {
      const generateTokenData: GenerateTokenData = {
        clientId,
        clientSecret,
        attempt: 1
      };

      dispatch(generateToken(generateTokenData));
    }
  }, [registerDeviceLoading, generateTokenLoading]);
};

export default useRegisterDeviceAndGenerateToken;
