import { FC, useContext, useEffect, useRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Contexts
import { ContentRefContext, LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPMobileAppFooter } from '../RPMobileAppFooter';
import FullLayoutHeader from '../../molecules/FullLayoutHeader';
import { HeaderType } from '../../molecules/FullLayoutHeader/FullLayoutHeader';
import RPFooter from '../RPFooter';
// Types
import { LayoutSize } from '../../../core/types/LayoutTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../core/types/LayoutProps';
import { LinkType } from '../../../core/types/GoogleAnalyticsTypes';
import { IconObject } from '../../../core/types/IconOrgTypes';
import { OrganizationCodeTypes } from '../../../core/types/OrganizationTypes';
// Redux
import { removePersistedStoreData } from '../../../redux/store';
// RTK Slice
import { getRegistrationDetails } from '../../../redux/modules/registrationDetailsSlice';
// Utils
import { getLandingPageURL, getLoginPageURL } from '../../../core/utils/GetOrganizationEnvSpecificURLs';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import { checkIfBrazilChangesApplicable } from '../../../core/utils/CheckIfBrazilChangesApplicable';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
// Constants
import { DISABLE_MOBILE_RELATED_FEATURES } from '../../../core/utils/Constants/Constants';

const FullContainer = styled('div')<LayoutProps>(({ theme, layout }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    margin: 0,
    padding: 0,
    overflow: 'auto',
    transition: 'all 0.2s ease 0s',
    ...(!isMobileLayout && { height: '100vh' }),
    width: '100%',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT
  };
});

interface CustomBoxProps extends BoxProps {
  size: LayoutSize;
  enableMobileFooter?: boolean;
  hideAdditionalInteractions?: boolean;
  layoutType?: HeaderType;
  stickHeaderAtTop: boolean;
}

const FullContentContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'size' &&
    prop !== 'enableMobileFooter' &&
    prop !== 'hideAdditionalInteractions' &&
    prop !== 'layoutType' &&
    prop !== 'stickHeaderAtTop'
})<CustomBoxProps>(({ theme, size, enableMobileFooter, hideAdditionalInteractions, layoutType, stickHeaderAtTop }) => {
  const {
    colours: {
      backgrounds: { background, backgroundAltInverse, backgroundAlt },
      text: { textInverse }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND: HexColour = background;
  const COLOUR_BG_BACKGROUND_ALT_INVERSE: HexColour = backgroundAltInverse;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;

  const isMobileLayout: boolean = isMobileLayoutApplicable(size);

  const isFullLayoutTypeOld: boolean = layoutType === 'old';

  return {
    overflow: 'auto',
    ...(stickHeaderAtTop && {
      paddingTop: isFullLayoutTypeOld ? '82px' : isMobileLayout ? '72px' : '130px'
    }),
    ...(!isMobileLayout &&
      !isFullLayoutTypeOld && {
        paddingBottom: '160px'
      }),
    width: '100%',
    height: isMobileLayout && enableMobileFooter ? 'calc(100vh - 120px)' : '100vh',
    boxSizing: 'border-box',
    backgroundColor: isFullLayoutTypeOld
      ? COLOUR_BG_BACKGROUND
      : hideAdditionalInteractions
      ? COLOUR_BG_BACKGROUND_ALT
      : COLOUR_BG_BACKGROUND_ALT_INVERSE,
    color: COLOUR_TEXT_TEXT_INVERSE
  };
});

interface ContentProps extends BoxProps {
  isOnlyLayoutView: boolean;
  expandContainerWidth?: boolean;
  layoutType?: HeaderType;
  size: LayoutSize;
  shouldAddWrapperContainerStyling: boolean;
  shouldShowMobileHeader: boolean;
}

const Content = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'size' &&
    prop !== 'isOnlyLayoutView' &&
    prop !== 'expandContainerWidth' &&
    prop !== 'layoutType' &&
    prop !== 'shouldAddWrapperContainerStyling' &&
    prop !== 'shouldShowMobileHeader'
})<ContentProps>(({ size, isOnlyLayoutView, layoutType, shouldAddWrapperContainerStyling, shouldShowMobileHeader }) => {
  const isFullLayoutTypeOld: boolean = layoutType === 'old';

  const isDesktopLayout: boolean = size === 'desktop';
  const isTabletLayout: boolean = size === 'tablet';

  return {
    display: 'flex',
    justifyContent: 'center',
    ...(isOnlyLayoutView && {
      border: '1px solid red',
      width: '100%',
      maxWidth: '500px',
      height: '100%',
      maxHeight: 450
    }),
    ...(isFullLayoutTypeOld && {
      boxSizing: 'border-box',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      ...(shouldAddWrapperContainerStyling && {
        maxWidth: isDesktopLayout ? '420px' : '350px'
      }),
      margin: '0px auto',
      height: 'fit-content',
      minHeight: 'calc(100vh - 164px)',
      ...(shouldAddWrapperContainerStyling && {
        paddingTop: isDesktopLayout ? '46px' : isTabletLayout ? '22px' : shouldShowMobileHeader ? '6px' : '0px',
        paddingBottom: '60px'
      })
    })
  };
});

const FullLayoutFooterWrapper = styled(Box)(({ theme }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  return {
    padding: '12px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: COLOUR_BG_BACKGROUND_ALT
  };
});

interface FullLayoutProps {
  children?: any;
  enableMobileFooter?: boolean;
  hideAdditionalInteractions?: boolean;
  expandContainerWidth?: boolean;
  layoutType?: HeaderType;
  stickHeaderAtTop?: boolean;
  shouldAddWrapperContainerStyling?: boolean;
}

const FullLayout: FC<FullLayoutProps> = (props: FullLayoutProps) => {
  const { layout: layoutSize } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);
  const { language } = useContext(LanguageContext);
  const { updateRef } = useContext(ContentRefContext);

  const location = useLocation();
  const isOnlyLayoutView: boolean = location.pathname.includes('full-layout');

  const containerRef = useRef<HTMLDivElement>(null);

  const isMobileLayout: boolean = isMobileLayoutApplicable(layoutSize);

  const lightLogoIcon: keyof IconObject = isMobileLayout ? 'logoLightMobile' : 'logoLight';

  const darkLogoIcon: keyof IconObject = isMobileLayout ? 'logoDarkMobile' : 'logoDark';

  const { shouldShowMobileHeader, shouldShowFullLayoutFooter, countryOfResidence } =
    useSelector(getRegistrationDetails);

  const {
    children,
    enableMobileFooter = true,
    hideAdditionalInteractions = false,
    expandContainerWidth = false,
    layoutType = 'normal',
    stickHeaderAtTop = true,
    shouldAddWrapperContainerStyling = false
  } = props;

  const currentLogo: keyof IconObject = hideAdditionalInteractions ? darkLogoIcon : lightLogoIcon;

  const isFullLayoutTypeOld: boolean = layoutType === 'old';

  const landingPageURL: string = getLandingPageURL(tenant);

  const loginPageURL: string = getLoginPageURL(tenant, language);

  const urlRedirectionOnCloseBtnClick: string = isFullLayoutTypeOld ? loginPageURL : landingPageURL;

  const showFooterContentVertically: boolean = isMobileLayout && isFullLayoutTypeOld;
  const footerSize: 'small' | 'normal' = showFooterContentVertically ? 'small' : 'normal';

  const shouldShowCloseIcon: boolean = isFullLayoutTypeOld || (!hideAdditionalInteractions && !isFullLayoutTypeOld);

  const { shouldApplyBrazilChanges } = checkIfBrazilChangesApplicable(tenant, countryOfResidence);

  const enableMobileAppFooter: boolean =
    enableMobileFooter &&
    !DISABLE_MOBILE_RELATED_FEATURES.includes(tenant as OrganizationCodeTypes) &&
    !isFullLayoutTypeOld &&
    !shouldApplyBrazilChanges;

  const {
    eventAction: { click },
    kycFailedDocument: {
      click: { googlePlayIcon, appStoreIcon }
    }
  } = GoogleAnalyticsTagsMapping;

  useEffect(() => {
    containerRef && containerRef.current && updateRef(containerRef);
  }, [containerRef]);

  const linkClick = (type: LinkType) => {
    TriggerGoogleAnalyticsTag(click, type === LinkType.AndroidStore ? googlePlayIcon : appStoreIcon);
  };

  const closeIconClickHandler = () => {
    if (isFullLayoutTypeOld) {
      removePersistedStoreData();
    }
    window.open(urlRedirectionOnCloseBtnClick, '_self');
  };

  return (
    <FullContainer layout={layoutSize} ref={containerRef} data-testid="rp-full-layout-container">
      <FullContentContainer
        size={layoutSize}
        enableMobileFooter={enableMobileAppFooter}
        hideAdditionalInteractions={hideAdditionalInteractions}
        layoutType={layoutType}
        stickHeaderAtTop={stickHeaderAtTop}
      >
        {shouldShowMobileHeader && (
          <FullLayoutHeader
            isOnlyLayoutView={isOnlyLayoutView}
            showCloseIcon={shouldShowCloseIcon}
            currentLogo={currentLogo}
            closeIconClickHandler={closeIconClickHandler}
            headerType={layoutType}
            stickHeaderAtTop={stickHeaderAtTop}
          />
        )}

        <Content
          size={layoutSize}
          layoutType={layoutType}
          isOnlyLayoutView={isOnlyLayoutView}
          expandContainerWidth={expandContainerWidth}
          shouldAddWrapperContainerStyling={shouldAddWrapperContainerStyling}
          shouldShowMobileHeader={shouldShowMobileHeader}
        >
          {children ? children : <div>Main Content Section</div>}
        </Content>
        {enableMobileAppFooter && (
          <RPMobileAppFooter size={isMobileLayout ? 'small' : 'large'} linkClickHandler={linkClick} />
        )}
        {isFullLayoutTypeOld && shouldShowFullLayoutFooter && (
          <FullLayoutFooterWrapper>
            <RPFooter size={footerSize} layoutType={layoutType} />
          </FullLayoutFooterWrapper>
        )}
      </FullContentContainer>
    </FullContainer>
  );
};

export default FullLayout;
