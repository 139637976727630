import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useEffectOnce } from 'usehooks-ts';
import { Box } from '@mui/system';
import styled from '@emotion/styled';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPText } from '../../atoms/RPText';
import { RPButton } from '../../atoms/RPButton';
import { RPHeaderRight } from '../../molecules/RPHeaderRight';
import { SplitLayout } from '../../organisms/SplitLayout';
// Hooks
import { useRegisterDeviceAndGenerateToken, useLanguageCookieAndIPCheck, useSetAffiliateCookie } from '../../../core/hooks';
// Types
import { LayoutSize } from '../../../core/types/LayoutTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Utils
import { getImage } from '../../../core/utils/ImageOrgData';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
// GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
// Constants
import { EN_US, ORGANIZATION_CODE_MAPPING } from '../../../core/utils/Constants/Constants';

interface LayoutProps {
  layout: LayoutSize;
}

const Container = styled(Box)<LayoutProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { text, textLink, textInverse, textLandingPage }
    }
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text;
  const COLOUR_TEXT_TEXT_LINK: HexColour = textLink;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;
  const COLOUR_TEXT_TEXT_LANDING_PAGE: HexColour = textLandingPage;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    ...(isMobileLayout && {
      alignItems: 'center'
    }),
    '& .MuiTypography-root': {
      whiteSpace: 'pre-line',
      color: isMobileLayout ? COLOUR_TEXT_TEXT : COLOUR_TEXT_TEXT_LANDING_PAGE,
      ...(isMobileLayout && {
        textAlign: 'center'
      })
    },
    '& .MuiTypography-root:nth-child(2)': {
      marginTop: isMobileLayout ? '30px' : '20px',
      ...(isMobileLayout && {
        marginBottom: '10px'
      })
    },
    '& .MuiButton-root': {
      marginTop: '40px',
      width: isMobileLayout ? '325px' : '420px',
      ...(isMobileLayout && {
        marginBottom: '15px'
      }),
      '> p': {
        color: COLOUR_TEXT_TEXT_INVERSE
      }
    },
    '& .MuiLink-root': {
      margin: '0px !important',
      color: COLOUR_TEXT_TEXT_LINK
    }
  };
});

const StyledImageContainer = styled('img')<LayoutProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    width: isMobileLayout ? '235px' : '100%',
    maxWidth: '540px'
  };
});

const Landing = () => {
  const { translations, language } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const { tenant } = useContext(TenantContext);

  const navigate = useNavigate();

  useRegisterDeviceAndGenerateToken();
  useLanguageCookieAndIPCheck();
  // Custom hook to set Affiliate and Asset Id cookie
  useSetAffiliateCookie();

  const LandingPageIcon = useMemo(() => getImage(tenant, 'landingPage'), [tenant]);
  const LandingPageUSAIcon = useMemo(() => getImage(tenant, 'landingPageUSA'), [tenant]);

  const LandingPageImg = (
    <StyledImageContainer
      src={LandingPageIcon}
      data-testid={'rp-landing-page-banner-image'}
      alt="Landing page image"
      layout={layout}
    />
  );
  const LandingPageUSAImg = (
    <StyledImageContainer
      src={LandingPageUSAIcon}
      data-testid={'rp-landing-page-usa-banner-image'}
      alt="Landing page USA image"
      layout={layout}
    />
  );

  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const headingTextVariant: 'h2' | 'h1' = isMobileLayout ? 'h2' : 'h1';
  const headingTextType = isDesktopLayout ? 'display-huge-bold' : isTabletLayout ? 'display-large-bold' : 'medium';
  const subHeadingTextVariant: 'body1' | 'h3' = isMobileLayout ? 'body1' : 'h3';
  const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';

  const {
    eventAction: { click, load },
    landingPage: {
      click: { landingPageOpenAccountCTAClicked },
      load: { landingPageLoad }
    }
  } = GoogleAnalyticsTagsMapping;

  const headingText: string = translate('landingPage.heading');
  const subHeadingText: string = translate('landingPage.subHeading');
  const openFreeAccountButtonText: string = translate('landingPage.openFreeAccountButton');

  useEffectOnce(() => {
    TriggerGoogleAnalyticsTag(load, landingPageLoad);
  });

  const openAccountClickHandler = () => {
    TriggerGoogleAnalyticsTag(click, landingPageOpenAccountCTAClicked);
    navigate('/register-page');
  };

  const currentTenant: string = tenant ? tenant : ORGANIZATION_CODE_MAPPING.currenciesdirect;
  const isCurrentLangUS: boolean = language === EN_US;
  const isCurrentTenantCD: boolean = currentTenant === ORGANIZATION_CODE_MAPPING.currenciesdirect;

  const bannerImage: JSX.Element = isCurrentLangUS && isCurrentTenantCD ? LandingPageUSAImg : LandingPageImg;

  return (
    <SplitLayout isLandingPage={true} layoutVariant={'full'}>
      <Container layout={layout}>
        {isMobileLayout && bannerImage}
        <RPText variant={headingTextVariant} type={headingTextType} text={headingText} />
        <RPText variant={subHeadingTextVariant} type="normal" text={subHeadingText} />
        <RPButton
          size={buttonSize}
          type="button"
          name={openFreeAccountButtonText}
          data-testid={'rp-open-free-account-button'}
          onClick={openAccountClickHandler}
        >
          {openFreeAccountButtonText}
        </RPButton>
        {isMobileLayout && <RPHeaderRight isLandingPage={true} />}
      </Container>
    </SplitLayout>
  );
};

export default Landing;
