import { useEffectOnce } from 'usehooks-ts';
import { CookieAttributes } from 'js-cookie';

// Hooks
import { useGetUrlParamValue } from '.';
// Types
import { OrganizationCodeTypes } from '../types/OrganizationTypes';
// Utils
import { CreateCookie as createCookie } from '../utils/CreateCookie';
import { GetOrganizationFromURL } from '../utils/GetOrganizationFromURL';
// Constants
import {
  SET_AFFILIATE_COOKIE_APPLICABLE_ORGANIZATIONS,
  ORGANIZATION_TO_DOMAIN_MAPPING,
  URL_AFFILIATE_ASSETID_COOKIE_NAMES,
  AFFILIATE_AND_ASSETID_COOKIE_OPTIONS
} from '../utils/Constants/Constants';

const useSetAffiliateCookie = () => {
  const tenant = GetOrganizationFromURL(window.location.href) as OrganizationCodeTypes;

  const { afflNoCookieName, assetIdCookieName } = URL_AFFILIATE_ASSETID_COOKIE_NAMES;

  const afflNoCookieValue: string | null = useGetUrlParamValue(afflNoCookieName);
  const assetIdCookieValue: string | null = useGetUrlParamValue(assetIdCookieName);

  useEffectOnce(() => {
    if (SET_AFFILIATE_COOKIE_APPLICABLE_ORGANIZATIONS.includes(tenant)) {
      const currentCookieDomain = ORGANIZATION_TO_DOMAIN_MAPPING[tenant];

      const currentAffiliateCookieOption = {
        ...AFFILIATE_AND_ASSETID_COOKIE_OPTIONS,
        domain: currentCookieDomain
      };

      const affiliateCookieOnRegister: { name: string; value: string | null; options: CookieAttributes }[] = [
        {
          name: afflNoCookieName,
          value: afflNoCookieValue,
          options: currentAffiliateCookieOption
        },
        {
          name: assetIdCookieName,
          value: assetIdCookieValue,
          options: currentAffiliateCookieOption
        }
      ];

      affiliateCookieOnRegister.forEach((cookie) => {
        if (cookie.value !== null) {
          createCookie(cookie.name, cookie.value, cookie.options);
        }
      });
    }
  });
};

export default useSetAffiliateCookie;