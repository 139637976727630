export const RESET_AUTHORISATION_DETAILS: string = 'state/authorisationDetails/reset';
export const RESET_ERROR_DETAILS: string = 'state/errorDetails/reset';
export const RESET_LOGIN_DETAILS: string = 'state/loginDetails/reset';
export const RESET_REGISTRATION_DETAILS: string = 'state/registrationDetails/reset';
export const RESET_REGISTRATION_STEPS_DETAILS: string = 'state/registrationStepsDetails/reset';
export const RESET_STORE_TO_INITIAL_STATE: string = 'resetToInitialState';
export const RESET_PARTIAL_STORE_TO_INITIAL_STATE_FROM_LANDING_PAGE: string = 'resetStoreToInitialStateFromLandingPage';
export const RESET_PARTIAL_STORE_TO_INITIAL_STATE_FOR_COR_BRAZIL_IN_CD_BRAND: string =
  'resetStoreToInitialStateForCorBrazilInCDBrand';
export const SET_APP_VERSION: string = 'setAppVersion';
