import { ReduxState } from '../core/types/ReduxStoreTypes';
import GenerateDefaultRegistrationSteps from '../core/utils/GenerateDefaultRegistrationSteps';
import GetBasicDetails from '../core/utils/GetBasicDetails';

const { countryCode, countryOfResidence, countryOfResidenceName } = GetBasicDetails();

export const initialState: ReduxState = {
  registrationDetails: {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    referred: 'no',
    referralCodeOrName: '',
    isDuplicateAccount: false,
    isDuplicateAccountCFX: false,
    isDuplicateAccountPFX: false,
    isDuplicateAccountLoading: 'idle',
    enquiryLeadLoading: 'idle',
    queryCOR: '',

    error: '',
    loading: 'idle',
    leadId: '',

    countryCode: countryCode,
    mobileNumber: '',
    generateOtpSuccess: false,
    mobileScreenError: '',
    isValidOTP: false,
    otpResponseMessage: '',
    otpValidationError: '',
    isETVExist: false,
    securityPin: '',
    updateLeadStatus: false,
    updateLeadLoading: 'idle',
    verifyETVLoading: 'idle',

    countryOfResidence: countryOfResidence,
    stateLicenseStatus: 2,
    countryOfResidenceName: countryOfResidenceName,
    additionalInfo: {},
    address: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      suburbCity: '',
      province: '',
      townCity: '',
      county: '',
      state: '',
      postcode: ''
    },
    dob: {
      day: '',
      month: '',
      year: ''
    },
    occupation: '',
    employmentStatus: '',
    employmentIndustry: '',
    usa: null,
    esp: null,
    bra: null,
    isDuplicateActAdvance: false,
    isDuplicateActAdvanceLoading: 'idle',
    // step 4
    currencyFrom: '',
    currencyTo: '',
    amount: '',
    agreeTC: false,
    addRegistrationLeadInProgress: false,
    crmAccountID: '',
    crmContactID: '',
    organizationLegalEntity: '',
    complianceStatus: '',
    isComplianceStatusComplete: false,
    complianceStatusLoading: 'idle',
    complianceCode: '',
    registrationLoading: 'idle',
    isPoaRequired: false,
    isPoiRequired: false,
    saveDocumentLoading: 'idle',
    uploadDocumentStatus: false,
    statusCode: '',
    tradeAccNo: '',
    sdkToken: '',
    biometricId: '',
    generateSDKTokenLoading: 'idle',
    createBiometricCheckLoading: 'idle',
    verifyIdentityBtnLoading: false,
    isOnfidoRequired: false,
    shouldShowMobileHeader: true,
    shouldShowFullLayoutFooter: true,
    shouldHideBurgerMenu: false,
    shouldHideLiveChatIon: false,
    hasZARModalBeenOpened: false,
    stateOfCountryOfResidence: ''
  },
  registrationSteps: {
    steps: GenerateDefaultRegistrationSteps()
  },
  loginDetails: {
    isLoginDialogShow: false
  },
  referenceData: {
    countryData: [],
    languageForCountryData: 'en-gb',
    dialingCodeData: [],
    countryLoading: 'idle',
    error: '',
    currenciesData: [],
    currenciesOptionData: [],
    currenciesLoading: 'idle',
    etvData: [],
    etvOptionData: [],
    etvLoading: 'idle',
    poiLoading: 'idle',
    poiDropdownOptions: [],
    poaLoading: 'idle',
    poaDropdownOptions: [],
    poiData: [],
    poaData: [],
    poiDocSides: [],
    poaDocSides: [],
    countryStateLoading: 'idle',
    countryStateData: {
      countryCode: '',
      stateInfo: []
    },
    selectedLanguage: '',
    locationLookupLoading: 'idle',
    ipBasedCountryCode: '',
    employmentStatusLoading: 'idle',
    employmentStatusData: [],
    employmentIndustryLoading: 'idle',
    employmentIndustryData: [],
    occupationDataLoading: 'idle',
    occupationData: [],
    referralRewardsLoading: 'idle',
    referralRewardsData: {
      legalEntity: '',
      sendAmt: '',
      sendAmtCurr: '',
      rewardAmt: '',
      rewardAmtCurr: ''
    },
    appVersion: '0.0.1'
  },
  errorDetails: {
    apiError: false,
    errorMessage: '',
    errorCode: ''
  },

  authorisationDetails: {
    registerDeviceLoading: 'idle',
    clientId: '',
    clientSecret: '',
    generateTokenLoading: 'idle',
    authorisationError: false
  }
};