// Types
import { OrganizationCodeTypes } from '../../types/OrganizationTypes';
// Utils
import GetBasicDetails from '../GetBasicDetails';
import { GetCookie as getCookie } from '../CreateCookie';
// Constants
import {
  COOKIES_TO_BE_CHECKED_FOR_BUSINESS_CFX_URL_MAPPING,
  NEW_DETECTED_BUSINESS_REGISTRATION_CFX_URL,
  ORGANIZATION_CODE_MAPPING
} from '../Constants/Constants';
// Feature Flags
import { featureFlags } from '../../FeatureFlags/FeatureFlags';

const {
  register: { shouldUseDetectedBusinessRegistrationCFXURL }
} = featureFlags;

type GetBusinessRegistrationCFXURLType = {
  businessRegistrationURL: string;
};

export const GetBusinessRegistrationCFXURL = (tenant: OrganizationCodeTypes | undefined): GetBusinessRegistrationCFXURLType => {
  const currentTenant = tenant ?? ORGANIZATION_CODE_MAPPING.currenciesdirect;

  const isCurrenciesDirectTenant = currentTenant === ORGANIZATION_CODE_MAPPING.currenciesdirect;
  const shouldUseNewRegistrationURL = isCurrenciesDirectTenant && shouldUseDetectedBusinessRegistrationCFXURL;

  // Get the correct URL based on feature flag & tenant
  const businessRegistrationURL = buildURL(shouldUseNewRegistrationURL);

  return {
    businessRegistrationURL
  };
};

const buildURL = (shouldUseNewRegistrationURL: boolean) => {
  if (!shouldUseNewRegistrationURL) {
    const { businessRegistrationCFXURL } = GetBasicDetails();
    return businessRegistrationCFXURL;
  }

  const queryParams = COOKIES_TO_BE_CHECKED_FOR_BUSINESS_CFX_URL_MAPPING.map((cookieMapping) => {
    const cookieValue = getCookie(cookieMapping.cookieName);
    return cookieValue ? `${cookieMapping.cookieKey}=${cookieValue}` : null;
  })
    .filter(Boolean)
    .join('&');

  return queryParams ? `${NEW_DETECTED_BUSINESS_REGISTRATION_CFX_URL}&${queryParams}` : NEW_DETECTED_BUSINESS_REGISTRATION_CFX_URL;
};
